/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import replaceSlashes from "../../node_modules/@lekoarts/gatsby-theme-minimal-blog/src/utils/replaceSlashes"
import useSiteMetadata from "../../node_modules/@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-site-metadata"
import useMinimalBlogConfig from "../../node_modules/@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config"

export default function Home() {
  const { siteTitle } = useSiteMetadata()
  const { basePath } = useMinimalBlogConfig()

  return(
      <div align='center'><br/>Page not found<br/><br/>
        <Link sx={{variant: "variants.styledLink"}} to={replaceSlashes(`/${basePath}`)}>{siteTitle}</Link>
      </div>
  ) 
}